import { createModel } from '@rematch/core';
import { is } from 'cypress/types/bluebird';
import _debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import Router from 'next/router';
import {
  ReportSaverState,
  ReportState,
} from 'src/components/ReportSaver/models';
import {
  createNewAttributeAPI,
  deleteContactAttributeMappingAPI,
  getBrevoAttributesForDropdownAPI,
  getContactMappingV2API,
  getDataSyncStatusAPI,
  patchContactMappingV2API,
  resyncAPI,
} from 'src/lib/api/brevo';
import {
  deleteReportAPI,
  fetchReportsAPI,
  patchReportAPI,
  sendReportNowAPI,
} from 'src/lib/api/reports';
import {
  fetchAttributionWindowAPI,
  fetchConsumptionAPI,
  fetchConsumptionV2API,
  fetchGILBreakdownAPI,
  fetchPrivacyAPI,
  saveAttributionWindowAPI,
  setPrivacyAPI,
} from 'src/lib/api/settings';
import { DO_NOT_IMPORT } from 'src/modules/email-onboarding/models';
import { RootModel } from 'src/store/models';

// debounced api call
const savePrivacy = _debounce(
  (privacy: any, successCallback: () => void, errorCallback: () => void) => {
    setPrivacyAPI(privacy).then(res => {
      if (!res.error) {
        successCallback();
      } else {
        errorCallback();
      }
    });
  },
  500,
);

export enum AttributionDurationLimits {
  DAYS = 28,
  HOURS = 672,
}

export enum AttributionAction {
  IMPRESSION = 'Impression based',
  CLICK = 'Click based',
  ALL = 'All Attributed Revenue',
}

export enum AttributionWindow {
  HOURS = 'Hours',
  DAYS = 'Days',
}

export const attributionActionDropdown = [
  { value: AttributionAction.ALL, label: AttributionAction.ALL },
  { value: AttributionAction.IMPRESSION, label: AttributionAction.IMPRESSION },
  { value: AttributionAction.CLICK, label: AttributionAction.CLICK },
];

export const transformAttributionSettingsResponse = ({
  attribution_type: type,
  attribution_duration_seconds: seconds,
}) => {
  let attributionAction = AttributionAction.ALL;
  let attributionDuration = 0;
  let attributionWindow = AttributionWindow.HOURS;

  if (type === 'delivered_interval')
    attributionAction = AttributionAction.IMPRESSION;
  else if (type === 'clicked_interval')
    attributionAction = AttributionAction.CLICK;

  if (seconds === null)
    return { attributionAction, attributionDuration, attributionWindow };

  /**
   * If the duration in seconds is perfectly divisible by 24 * 3600 = 1 day
   * use DAYS as the window, else, use HOURS with the corresponding duration
   *
   * ex:
   * 86400 seconds / (24 * 3600) = 1, make it "1 Day"
   * 90000 / (24 * 3600) = 1.04, convert to hours, make it "25 Hours"
   */
  if (seconds % (24 * 3600) === 0) {
    attributionWindow = AttributionWindow.DAYS;
    attributionDuration = seconds / (24 * 3600);
  } else attributionDuration = seconds / 3600;

  return { attributionAction, attributionDuration, attributionWindow };
};

export const transformAttributionSettingsRequest = ({
  attributionAction: action,
  attributionWindow: window,
  attributionDuration: duration,
}) => {
  let attribution_type = null;
  let attribution_duration_seconds = null;

  if (action === AttributionAction.IMPRESSION)
    attribution_type = 'delivered_interval';
  else if (action === AttributionAction.CLICK)
    attribution_type = 'clicked_interval';

  /**
   * If window is in HOURS, duration in seconds = attributionDuration * 3600
   * else if DAYS, duration in seconds = attributionDuration * 24 * 3600
   */
  if (action !== AttributionAction.ALL)
    attribution_duration_seconds =
      window === AttributionWindow.HOURS
        ? duration * 3600
        : duration * 24 * 3600;

  return { attribution_type, attribution_duration_seconds };
};

const defaultConsumptionV2 = {
  attributionLimitConsumed: 0,
  attributionLimit: 0,
  attributionLimitRemaining: 0,
  resetDate: null,
  breakdown: null,
};

const initialState = () => ({
  consumption: {
    isFetching: true,
    impressionsConsumed: 0,
    impressionsLimit: 0,
    impressionsRemaining: 0,
    resetDate: null,
    breakdown: null,
  },

  consumptionV2: {
    isFetching: true,
    webpush: { ...defaultConsumptionV2 },
    email: { ...defaultConsumptionV2 },
    sms: { ...defaultConsumptionV2 },
  },

  privacy: {
    isSaving: false,
    isChanged: false,
    isFetching: true,
    admin_access: false,
    customer_id: false,
    ip: 'no_ip', // or 'forget_last_octet'
    location: false,
    notification_preference: {
      enabled: false,
      message: '',
      actions: {
        access_data: {
          enabled: false,
          text: '',
        },
        delete_data: {
          enabled: false,
          text: '',
        },
        unsubscribe: {
          enabled: false,
          text: '',
        },
      },
    },
    subscriber_info: {
      email: 'full',
      location: true,
      name: true,
    },
  },

  reports: {
    isFetching: true,
    count: 0,
    next: null,
    previous: null,
    results: [] as ReportSaverState[],
  },

  attributionWindow: {
    isFetching: true,
    attributionAction: AttributionAction.ALL,
    attributionDuration: 1,
    attributionWindow: AttributionWindow.DAYS,
  },
  newAttributeModal: null as null | number,
  isCreatingAttribute: false as boolean,
  contactMappingV2: [] as ContactMapping[],
  newContactMapping: [] as ContactMapping[],
  dropDownAttribute: [] as DropDownAttribute[],
  dataSyncStatus: null as null | string,
  isResyncing: false as boolean,
  showDeleteAttributeModal: null as null | number,
  isDeletingAttribute: false as boolean,
  showAttributeMismatchModal: false as boolean,
  mismatchModalData: {
    show: false as boolean,
    newContactMapping: null as null | ContactMapping,
  },
  loadingAttributes: true as boolean,
  canResync: false as boolean,
});

type SettingsState = ReturnType<typeof initialState>;

export type ContactMapping = {
  id: number;
  attribute_name: string;
  attribute_type: string;
  mapped_attribute_name: string;
  mapped_attribute_type: string;
  display_name: string;
};

export type DropDownAttribute = {
  name: string;
  category: string;
  type: string;
};

const settings = createModel<RootModel>()({
  state: initialState(),

  effects: dispatch => ({
    async fetchGILBreakdown() {
      const { data, error } = await fetchGILBreakdownAPI();
      return error ? [] : data;
    },

    async fetchConsumption(_, rootState) {
      const { user } = rootState.user;

      if (user?.website?.flags?.brevo_email === 'enabled') {
        await this.fetchConsumptionV2();
      }

      const { data, error } = await fetchConsumptionAPI();

      if (error) {
        dispatch.saveToast.showError('Error fetching consumption');
        return;
      }

      const {
        impressions_consumed,
        impressions_limit,
        impressions_remaining,
        reset_date,
        breakdown,
      } = data;

      this.storeConsumption({
        impressionsConsumed: impressions_consumed,
        impressionsLimit: impressions_limit,
        impressionsRemaining: impressions_remaining,
        resetDate: reset_date,
        breakdown,
      });
    },

    async fetchConsumptionV2() {
      const { data, error } = await fetchConsumptionV2API();

      if (error) {
        dispatch.saveToast.showError('Error fetching consumption');
        return;
      }

      const { webpush, email, sms } = data;

      const webpushConsumption = webpush
        ? {
            attributionLimitConsumed: webpush.attribution_limit_consumed,
            attributionLimit: webpush.attribution_limit,
            attributionLimitRemaining: webpush.attribution_limit_remaining,
            resetDate: webpush.reset_date,
            breakdown: webpush.breakdown,
          }
        : { ...defaultConsumptionV2 };

      const emailConsumption = email
        ? {
            attributionLimitConsumed: email.attribution_limit_consumed,
            attributionLimit: email.attribution_limit,
            attributionLimitRemaining: email.attribution_limit_remaining,
            resetDate: email.reset_date,
            breakdown: email.breakdown,
          }
        : { ...defaultConsumptionV2 };

      const smsConsumption = sms
        ? {
            attributionLimitConsumed: sms.attribution_limit_consumed,
            attributionLimit: sms.attribution_limit,
            attributionLimitRemaining: sms.attribution_limit_remaining,
            resetDate: sms.reset_date,
            breakdown: sms.breakdown,
          }
        : { ...defaultConsumptionV2 };

      this.storeConsumptionV2({
        webpush: webpushConsumption,
        email: emailConsumption,
        sms: smsConsumption,
      });
    },

    async fetchPrivacy() {
      await fetchPrivacyAPI().then(res => {
        if (!res.error) {
          this.storePrivacy(res.data);
        }
      });
    },

    async fetchReports(nextUrl: string, rootState) {
      const currentState = rootState.settings.reports;

      this.storeReports({ isFetching: true });

      const { data, error } = await fetchReportsAPI(nextUrl);

      if (error) {
        this.storeReports({ isFetching: false });
        dispatch.saveToast.showError('Error fetching reports');
        return;
      }

      const newState = {
        results: nextUrl
          ? [...currentState.results, ...data.results]
          : data.results,
        count: data.count,
        next: data.next,
        previous: data.previous,
      };
      this.storeReports({ ...newState, isFetching: false });
    },

    /* eslint-disable consistent-return */
    async fetchAttributionWindow() {
      const { data, error } = await fetchAttributionWindowAPI();

      if (error) return dispatch.saveToast.showError('Error fetching settings');

      const { value } = data[0];

      this.storeAttributionWindow(transformAttributionSettingsResponse(value));
    },

    async setPrivacyProp(
      payload: Partial<ReturnType<typeof initialState>['privacy']> = {
        isFetching: false,
      },
      rootState,
    ) {
      const prevState = rootState.settings.privacy;
      const privacy = { ...prevState, ...payload };

      this.storePrivacy({
        isFetching: false,
        isSaving: true,
        ...privacy,
      });

      // save privacy data to backend (with debounce)
      savePrivacy(
        privacy,
        () => {
          dispatch.saveToast.showDone('Changes saved');
        },
        () => {
          this.storePrivacy(prevState);
        },
      );
    },

    async setPrivacyWidgetData(
      payload: Partial<ReturnType<typeof initialState>['privacy']> = {
        isFetching: false,
      },
      rootState,
    ) {
      const state = rootState.settings.privacy;
      this.storePrivacy({ ...state, ...payload, isChanged: true });
    },

    async savePrivacyChanges(_, rootState) {
      const state = rootState.settings.privacy;

      this.storePrivacy({ ...state, isSaving: true });
      const request = omit(state, ['isFetching', 'isSaving', 'isChanged']);
      const res = await setPrivacyAPI(request);
      if (!res.error) {
        this.storePrivacy({ ...state, isSaving: false, isChanged: false });
        Router.push('/settings?tab=privacy');
        dispatch.saveToast.showDone('Changes saved');
      } else {
        this.storePrivacy({ ...state, isSaving: false, isChanged: true });
        dispatch.saveToast.showError('Changes not saved');
      }
    },

    async sendReportNow(id: number) {
      const { error } = await sendReportNowAPI(id);

      if (error) dispatch.saveToast.showError('Error sending report');
      else dispatch.saveToast.showDone('Report is sent');
    },

    async setAsNonRecurringReport(id: number, rootState) {
      const { results } = rootState.settings.reports;

      const report = results.find(r => r.id === id);
      report.isRecurringSelected = false;

      this.storeReports({ results: [...results] });

      const { error } = await patchReportAPI({
        id,
        schedule: {},
        state: ReportState.SENT,
        name: report.reportType,
      });

      if (error) dispatch.saveToast.showError('Error updating settings');
      else dispatch.saveToast.showDone('Report Updated');
    },

    async deleteReport(id: number, rootState) {
      const { results } = rootState.settings.reports;

      this.storeReports({ results: results.filter(r => r.id !== id) });

      const { error } = await deleteReportAPI(id);
      if (error) dispatch.saveToast.showError('Error deleting report');
      else dispatch.saveToast.showDone('Report is deleted');
    },

    async saveAttributionSettings(settings: {
      attributionDuration: number;
      attributionWindow: AttributionWindow;
      attributionAction: AttributionAction;
    }) {
      const { error } = await saveAttributionWindowAPI({
        preferences: [
          {
            key: 'attribution_window',
            value: transformAttributionSettingsRequest(settings),
          },
        ],
      });

      if (error) return dispatch.saveToast.showError('Error saving settings');

      this.storeAttributionWindow(settings);

      dispatch.saveToast.showDone(`Attribution Model changed`);
    },

    async fetchContactMappingV2() {
      const { data, error } = await getContactMappingV2API();
      if (error) {
        dispatch.saveToast.showError('Error fetching contact mapping');
      } else {
        const modifiedData = data.map(item => ({
          ...item,
          mapped_attribute_name: item.mapped_attribute_name === "" ? DO_NOT_IMPORT : item.mapped_attribute_name,
        }));
        this.storeContactMappingV2(modifiedData);
        this.storeState({ loadingAttributes: false });
      }
    },

    async fetchDropContactMapping() {
      const { data, error } = await getBrevoAttributesForDropdownAPI();
      if (error) {
        dispatch.saveToast.showError('Error fetching dropdown attributes');
      } else {
        this.storeDropContactMapping(data);
      }
    },

    async fetchDataSyncStatus() {
      const { data, error } = await getDataSyncStatusAPI();
      if (error) {
        dispatch.saveToast.showError('Error fetching data sync status');
      } else {
        this.storeDataSyncStatus(data);
      }
    },

    async updateContactAttributeMapping(_, rootState) {
      const { newContactMapping } = rootState.settings;

      const updatedMapping = newContactMapping.map(item => ({
        ...item,
        mapped_attribute_type: item.mapped_attribute_type,
        mapped_attribute_name: item.mapped_attribute_name === DO_NOT_IMPORT ? "" : item.mapped_attribute_name,
      }));

      const { error, status } = await patchContactMappingV2API(updatedMapping);
      if (error) {
        if (status === 400) {
          dispatch.saveToast.showError('Bad request: Invalid contact mapping');
        } else {
          dispatch.saveToast.showError('Error updating contact mapping');
        }
        return false;
      }
      this.storeState({ canResync: true });
      dispatch.saveToast.showDone('Contact mapping updated');
      return true;
    },

    async createNewAttribute(
      payload: {
        name: string;
        categories: string[];
        type: string;
      },
      rootState,
    ) {
      const id = rootState.settings.newAttributeModal;

      this.storeState({ isCreatingAttribute: true });

      const { error } = await createNewAttributeAPI(payload);
      if (error) {
        dispatch.saveToast.showError(typeof error === 'string' ? error : error.message);
      } else {
        dispatch.saveToast.showDone('New attribute created');
        const { dropDownAttribute, contactMappingV2 } = rootState.settings;
        const updatedContactMapping = contactMappingV2.map(attr =>
          attr.id === id
            ? {
                ...attr,
                mapped_attribute_name: payload.name,
                mapped_attribute_type: payload.type
              }
            : attr,
        );
        const newModifiedAttribute = updatedContactMapping.find(attr => attr.id === id);
        this.storeState({
          dropDownAttribute: [...dropDownAttribute, payload],
          contactMappingV2: updatedContactMapping,
          newContactMapping: [...rootState.settings.newContactMapping, newModifiedAttribute],
        });
        this.setAttributeModal(null);
      }

      this.storeState({ isCreatingAttribute: false });
    },

    async triggerResync() {
      this.storeState({ isResyncing: true });
      const updateSuccess =
        await dispatch.settings.updateContactAttributeMapping();
      if (updateSuccess) {
        const { error } = await resyncAPI();
        if (error) {
          dispatch.saveToast.showError('Error triggering resync');
        } else {
          dispatch.saveToast.showDone('Resync triggered');
          this.storeState({ canResync: false });
        }
      }
      this.storeState({ isResyncing: false });
    },
    async removeAttributeMapping(_, rootState) {
      this.storeState({ isDeletingAttribute: true });
      const { error } = await deleteContactAttributeMappingAPI([
        {
            "id": rootState.settings.showDeleteAttributeModal,
            "is_active": false
        }
      ])
      if (error) {
        dispatch.saveToast.showError('Error deleting attribute');
      } else {
        dispatch.saveToast.showDone('Attribute deleted');
        const { contactMappingV2 } = rootState.settings;
        this.storeState({
          contactMappingV2: contactMappingV2.filter(
            attr => attr.id !== rootState.settings.showDeleteAttributeModal,
          ),
        });
        this.storeState({ showDeleteAttributeModal: null });
        this.storeState({ canResync: true });
      }

    }
  }),

  reducers: {
    storeConsumption(state, payload) {
      return {
        ...state,
        consumption: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storeConsumptionV2(state, payload) {
      return {
        ...state,
        consumptionV2: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storePrivacy(state, payload) {
      return {
        ...state,
        privacy: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storeReports(state, payload) {
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    },

    storeAttributionWindow(state, payload) {
      return {
        ...state,
        attributionWindow: {
          ...payload,
          isFetching: false,
        },
      };
    },

    storeContactMappingV2(state, payload) {
      return {
        ...state,
        contactMappingV2: payload,
      };
    },

    updateSingleAttributeMapping(state, payload: ContactMapping) {
      return {
        ...state,
        contactMappingV2: state.contactMappingV2.map(attr =>
          attr.id === payload.id ? { ...attr, ...payload } : attr,
        ),
        newContactMapping: [
          ...state.newContactMapping.filter(attr => attr.id !== payload.id),
          payload,
        ],
      };
    },

    storeDropContactMapping(state, payload) {
      return {
        ...state,
        dropDownAttribute: payload.contact_attributes,
      };
    },

    storeDataSyncStatus(state, payload) {
      return {
        ...state,
        dataSyncStatus: payload.customer.last_updated,
      };
    },

    setAttributeModal(state, payload: number | null) {
      return {
        ...state,
        newAttributeModal: payload,
      };
    },

    storeState(state, payload: Partial<SettingsState>) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default settings;
