import { del, get, patch, post, put } from './axios';

const BREVO_SUBACCOUNT_ENDPOINT = '/dashboard/brevo/sub-accounts/';

const FRAUD_DETECTION_ENDPOINT = '/dashboard/brevo/fraud-detection/';

const PHONE_VERIFICATION_ENDPOINT = '/dashboard/brevo/phone-verification/';

export const verifyPhoneNumber = (phoneNumber: string, source: string) =>
  post(PHONE_VERIFICATION_ENDPOINT, { phone_number: phoneNumber, source });

export const verifyOTP = (phoneNumber: string, otp: Number) =>
  post(`${PHONE_VERIFICATION_ENDPOINT}otp/`, {
    phone_number: phoneNumber,
    otp,
  });

export const getFraudDetectionAPI = () => get(FRAUD_DETECTION_ENDPOINT);

export const submitFraudDetectionAPI = ({ email, talon }) =>
  post(FRAUD_DETECTION_ENDPOINT, { email, talon });

export const fetchSubAccountAPI = () => get(BREVO_SUBACCOUNT_ENDPOINT);

export const getSenderAPI = () => get(`${BREVO_SUBACCOUNT_ENDPOINT}senders/`);

export const createSenderAPI = (payload: { email: string; name: string }) =>
  post(`${BREVO_SUBACCOUNT_ENDPOINT}senders/`, payload);

export const validateSenderAPI = (senderId: number, code: number) =>
  put(`${BREVO_SUBACCOUNT_ENDPOINT}senders/${senderId}/validate/`, {
    otp: code,
  });

export const fetchDomainAPI = () => get(`${BREVO_SUBACCOUNT_ENDPOINT}domains/`);

export const createDomainAPI = (payload: { domainName: string }) =>
  post(`${BREVO_SUBACCOUNT_ENDPOINT}domains/`, {
    domain_name: payload.domainName,
  });

export const authenticateDomainAPI = () =>
  put(`${BREVO_SUBACCOUNT_ENDPOINT}domains/validate/`, {});

export const fetchRedirectURLAPI = (payload = '') => {
  if (payload.length) {
    return post(`${BREVO_SUBACCOUNT_ENDPOINT}sso-redirect-url/`, {
      target_url: payload,
    });
  }
  return post(`${BREVO_SUBACCOUNT_ENDPOINT}sso-redirect-url/`, {});
};

export const brevoProxyLogin = () =>
  post(`${BREVO_SUBACCOUNT_ENDPOINT}proxy-auth`, {}, { withCredentails: true });

export const brevoProxyClearCookie = () =>
  fetch(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/v1/accounts/brevo/proxy-logout?platform=shopify`,
    {
      method: 'POST',
      credentials: 'include',
    },
  );

export const getAccountInfoAPI = (temporaryToken: string) =>
  get(
    `/dashboard/brevo/integration/account/info/?temporary_token=${temporaryToken}`,
  );

export const connectBrevoAPI = (temporaryToken: string) =>
  post(
    `/dashboard/brevo/integration/account/connect/?temporary_token=${temporaryToken}`,
    {},
  );

export const fetchDataSyncStatusAPI = () =>
  get(`/dashboard/brevo/datasync-status/`);

export const startDataSyncAPI = () =>
  post(`/dashboard/brevo/start-datasync/`, {});

export const getContactMappingAPI = () =>
  get(`/dashboard/brevo/contact-attribute-mapping/`);

export const getContactAttributesAPI = () =>
  get(`/dashboard/brevo/contact-attributes`);

export const getListMappingAPI = ({
  offset = 0,
  folderId,
}: {
  offset: number;
  folderId: number;
}) =>
  get(
    `/dashboard/brevo/folders/lists/?folder_id=${folderId}&limit=50&offset=${offset}`,
  );

export const getfoldersAPI = () =>
  get(`/dashboard/brevo/folders/?limit=10&offset=0`);

export const saveContactMappingAPI = data =>
  put(`/dashboard/brevo/contact-attribute-mapping/`, data);

export const createNewAttributeAPI = data =>
  post(`/dashboard/brevo/contact-attributes/`, data);

export const createNewListAPI = data => post(`/dashboard/brevo/lists/`, data);

export const getListsAPI = ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}) => get(`/dashboard/brevo/lists/?limit=${limit}&offset=${offset}`);

export const saveListAPI = data => put(`/dashboard/brevo/list-mapping/`, data);

export const getListSelectionAPI = () => get(`/dashboard/brevo/list-mapping/`);

export const getOptInsAPI = () =>
  get(`/dashboard/brevo/subscription-confirmation/`);

export const getFinalTemplatesAPI = () =>
  get(
    `/dashboard/brevo/subscription-confirmation/templates/?template_type=final`,
  );
export const getDoubleTemplatesAPI = () =>
  get(
    `/dashboard/brevo/subscription-confirmation/templates/?template_type=doi`,
  );

export const saveOptInsAPI = (data: {
  subscription_confirmation: boolean;
  config: {
    email_confirmation_type: 'simple_confirmation' | 'double_confirmation';
    confirmation_template_id: number;
    is_confirmation_page: boolean;
    confirmation_page_url: string;
    is_double_final_confirmation_email: boolean;
    simple_final_confirmation_template_id: number;
    double_final_confirmation_template_id: number;
  };
}) => put(`/dashboard/brevo/subscription-confirmation/`, data);

export const resyncAPI = () => post(`/dashboard/brevo/resync/`, {});

export const fetchContactAttributesAPI = () =>
  get(`/dashboard/brevo/contact-attributes/`);

export const createContactAttributeAPI = (attribute: {
  name: string;
  type: string;
}) => post(`/dashboard/brevo/contact-attributes/`, attribute);

export const getContactMappingV2API = () =>
  get(`/dashboard/brevo/contact-attributes-mapping/`, null, { version: 'v2' });

export const patchContactMappingV2API = data =>
  patch(`/dashboard/brevo/contact-attributes-mapping/`, data, {
    version: 'v2',
  });

export const deleteContactAttributeMappingAPI = data =>
  patch(`/dashboard/brevo/contact-attributes-mapping/`, data, {
    version: 'v2',
  });

export const getBrevoAttributesForDropdownAPI = () =>
  get(`/dashboard/brevo/contact-attributes/?platform=shopify`);

export const getDataSyncStatusAPI = () =>
  get(`/dashboard/brevo/datasync-entities-status/`);
export const getEmailStatsAllTime = () =>
  get(`/dashboard/attributed-revenue/summary?channel=email`);

export const getEmailStatsTimeFilter = (start_date, end_date) =>
  get(
    `/dashboard/attributed-revenue/summary?start_date=${start_date}&end_date=${end_date}&channel=email`,
  );
