import { FullStory } from '@fullstory/browser';
import { hexToRgb, rgbToHsb } from '@shopify/polaris';
import tokens from 'barn/tokens';
import { getYear } from 'date-fns';
import posthog from 'posthog-js';
import { getStorage } from 'src/lib/storage';
import { identify } from 'src/lib/tracking';
import { loadAnalytics } from 'src/lib/tracking/utils';
import { ReservedElementNodeIds } from 'src/modules/optins/components/FormWidget/lib/constants';
import { TreeNode } from 'src/modules/optins/components/FormWidget/lib/types';
import { isCustomFieldNode } from 'src/modules/optins/components/FormWidget/lib/utils';
import { OptinsData } from 'src/modules/optins/models';
import {
  DeviceType,
  FormCollectionNode,
  FormWidget,
  OptinFlow,
  OptinFlowNode,
  WaitTimeNode,
} from 'src/modules/optins/models/types';
import { getTreeNodeById } from 'src/modules/optins/util/treeUtils';
import { PricingSlab } from 'src/modules/pricing-v2/models/types';
import store from 'src/store';
import {
  ChannelDisplayName,
  FlowNodeType,
  FormWidgetType,
  OptinRenderType,
  OptinType,
  Status,
} from './constants';
import { logErrorToSentry } from './debug-utils';

/**
 * Utility for exhaustive conditionals when checking a single var.
 * Ensures all possible well-typed possibilities are accounted for,
 * with a default value in the case of TS being overridden.
 *
 * Example:-
 *
 * let var: "str1" | "str2";
 *
 * ...
 *
 * if(var === "str1") return 1;
 *
 * else if(var === "str2") return 2;
 *
 * else return getUnreachableDefault(var, 3);
 *
 * @param {never} neverVar The var whose possible values are being checked
 * @param {any} defaultAlt A usable default value if executed at runtime
 * @returns {typeof defaultAlt} defaultAlt
 */
export const getUnreachableDefault = (neverVar: never, defaultAlt: any) =>
  defaultAlt;

export function isClientSide() {
  return typeof window !== 'undefined';
}

export function isSmallScreen() {
  if (
    isClientSide() &&
    window.matchMedia(`(max-width: ${tokens.responsiveScreenBreakpoint})`)
      .matches
  ) {
    return true;
  }

  return false;
}

export const getSystemTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark';
  }

  return 'light';
};

export const capitalize = phrase =>
  phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const secureUserObject = user => {
  if (!user) return user;

  /**
   * Without explicitly creating a new website object, the below
   * intercom hash deletion will also affect the original user object
   */
  const newUser = { ...user, website: { ...user?.website } };

  delete newUser.websites;
  delete newUser.token;
  delete newUser.website?.intercom_identity_hash;

  return newUser;
};

/* eslint-disable */
export function getTimezone() {
  const date = new Date();
  let fullName = 'Indian Standard Time';
  try {
    fullName = date.toString().split('(')[1].split(')')[0];
  } catch (err) {
    console.error(err);
  }

  fullName = fullName.replace('India ', 'Indian ');

  const offset = date.getTimezoneOffset();
  const location = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return {
    location,
    offset,
    fullName,
  };
}

/**
 * Starts a conversation from the user's end with the passed message. Uses Intercom
 * if available, otherwise opens default mail client.
 * @param message Message to start conversation with
 */
export function startConversationWithUser(message) {
  // If this is not present, Intercom has been blocked
  if (document.querySelector('#intercom-frame')) {
    window.Intercom('showNewMessage', message);
    return;
  }

  const a = window.document.createElement('a');
  a.href = `mailto:support@pushowl.com?subject=${encodeURIComponent(message)}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
}
export default { getTimezone };

/**
 * Converts a number to compact form according to locale.
 * Eg. 1000 to 1K, 120180 to 120K for en-US locale
 * @param number Number Number to format
 */
export function getCompactNumber(number) {
  // using try-catch here bcoz Intl.Number fails on mac 10.x + safari 14 combi
  // Ref: https://gitmemory.com/issue/google/site-kit-wp/3255/830391313
  try {
    return new Intl.NumberFormat(undefined, {
      //@ts-ignore
      notation: 'compact',
    }).format(number);
  } catch (e) {
    return number;
  }
}

/**
 * Will pause async execution for the given delay
 * @param delay time in seconds
 */
export const asyncWait = async (delay: number) =>
  new Promise(r => setTimeout(() => r(null), delay));

export const isValidDate = (date: string) => {
  // @ts-ignore
  const isValid = new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));

  if (isValid) {
    // Checking if year has only 4 digits since it is not valid in Chrome but valid in Safari
    return getYear(new Date(date)).toString().length === 4;
  }

  return isValid;
};

export const getDiscountCodeFromURL = (url: string | null) => {
  if (url) {
    try {
      // Decoding URL twice since Shopify double encodes them
      const urlWithoutQueryParam = decodeURIComponent(
        decodeURIComponent(url),
      ).split(/[?#]/)[0];

      const discountCodeURL = urlWithoutQueryParam.split(/\/discount\//gi);

      if (discountCodeURL.length > 1) {
        return discountCodeURL[1] || '';
      }

      // eslint-disable-next-line no-empty
    } catch (_) {}
  }
  return null;
};

export const isRunningEmbedded = () => {
  return window.top !== window.self;
};

export const openChargeUrl = (url: string) => {
  const storage = getStorage();
  const platform = storage.get('user')?.website?.platform || 'shopify';

  // Specifically for embedded mode in Shopify
  // TODO: replace with app bridge later
  if (isRunningEmbedded() && platform === 'shopify') {
    const win = window.open(url, '_blank');

    // refresh the page when charge window is closed
    const timer = setInterval(function () {
      if (win == null || win.closed) {
        clearInterval(timer);
        window.location.reload();
      }
    }, 1000);
  } else {
    window.location.href = url;
  }
};

export const handleRedirect = (url: string) => {
  try {
    const query = new URLSearchParams(window.location.search);
    const isShopifyMarketingAutomation =
      query.get('shopifyAutomation') === 'marketing';

    if (isShopifyMarketingAutomation) {
      window.open(url, '_blank');
      return;
    }

    window.location.href = url;
  } catch (error) {}
};

export const formatTestHandle = (value: string) =>
  value.split(' ').join('-').toLowerCase();

export const isLocalStorageAvailable = () => {
  try {
    const test = 'pushowl_test';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const isSessionStorageAvailable = () => {
  try {
    const test = 'pushowl_test';
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const handlePostLogin = async ({ user }) => {
  store.dispatch.user.setIsAuthenticating(false);

  // Handle user object
  const storage = getStorage();
  store.dispatch.user.setUser(user);
  store.dispatch.user.setUserLoggedIn(true);
  storage.set('user', user);
  window._po_subdomain = user.website.subdomain;
  store.dispatch.integratedDashboard.fetchOrderCount();

  try {
    if (!user.skipAnalytics) {
      initTracking(user);
    } else {
      posthog.stopSessionRecording();
    }

    if (user?.website?.flags?.brevo_mfe === 'enabled') {
      await store.dispatch.brevomfe.brevoProxyLogin();
    }
    await store.dispatch.settings.fetchConsumption();
    await store.dispatch.settings.fetchAttributionWindow();
    await store.dispatch.home.fetchThemeAppExtensionStatus();
  } catch (error) {
    logErrorToSentry({
      error,
    });
  }
};

const initTracking = user => {
  const { subdomain, intercom_identity_hash: user_hash } = user.website;
  const { email, name, created_at } = user;

  const traits = {
    email,
    name,
    created_at: new Date(created_at).getTime() / 1000,
    subdomain,
  };

  const storage = getStorage();
  const loginType = storage.get('login_type');
  if (loginType === 'support') return;

  const segmentData = {
    subdomain,
    isSupportLogin: false,
    traits,
  };

  // TODO remove segmentData from user model
  store.dispatch.user.setSegmentData(segmentData);

  // check if it is small screen
  if (window.innerWidth < 768) {
    window.intercomSettings = {
      app_id: `${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}`,
      alignment: 'left',
      horizontal_padding: 10,
      vertical_padding: 10,
    };
  } else {
    window.intercomSettings = {
      app_id: `${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}`,
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 61,
    };
  }

  if (isLocalStorageAvailable()) {
    FullStory('setIdentity', {
      uid: user.website.subdomain,
      properties: { ...traits, isSupportLogin: user.skipAnalytics },
    });
  }

  identify(subdomain, traits);
  loadAnalytics({
    app_id: `${process.env.NEXT_PUBLIC_INTERCOM_API_KEY}`,
    user_hash,
    name,
    user_id: subdomain,
  });
};

export const getUserFirstName = user => {
  return user?.name?.split(' ')?.[0] || '';
};

/**
 * TODO:codesmell - This is done because the dates are sometimes string even though the TS type explicitly mentions it as Date
 * */
export const getSafeDate = (
  dateOrString?: Date | string,
  defaultsTo?: Date,
) => {
  return typeof dateOrString === 'string'
    ? new Date(dateOrString)
    : dateOrString || defaultsTo || new Date();
};

/**
 * sorts optinflows inplace where the active flows always come first, with rest of the order unchanged
 */
export const sortOptinFlowsByStatus = (optinFlows: OptinFlow[]) => {
  optinFlows.sort((a, b) => {
    if (a.status === Status.ACTIVE && b.status !== Status.ACTIVE) {
      return -1;
    } else if (a.status !== Status.ACTIVE && b.status === Status.ACTIVE) {
      return 1;
    } else {
      return 0;
    }
  });
};

/**
 * sorts and returns new form widgets array where the teaser always comes last,
 * with rest of it sorted by step type (step_1, step_2)
 */
export const sortFormWidgetsByStepType = (forms: FormWidget[]) => {
  const sortedForms = [...forms]?.sort((a, b) => {
    // teaser always comes last
    if (
      a.step_type === FormWidgetType.TEASER &&
      b.step_type !== FormWidgetType.TEASER
    ) {
      return 1;
    } else if (
      a.step_type !== FormWidgetType.TEASER &&
      b.step_type === FormWidgetType.TEASER
    ) {
      return -1;
    } else {
      return a.step_type.localeCompare(b.step_type); // sort steps in ascending order
    }
  });

  return sortedForms;
};

export const isValidHexColor = (hex: string): boolean => {
  return /^#[A-Fa-f0-9]{3}(?:[A-Fa-f0-9]{3})?$/.test(hex);
};

export const hexToHsb = (hex: string) => rgbToHsb(hexToRgb(hex));

/**
 * returns an array of all the resereved tree node elements (email, phone, button, etc)
 * within the `baseNode`
 */
export const getReservedTreeNodeElements = (baseNode: TreeNode) => {
  const reservedNodes = ReservedElementNodeIds.map(nodeId => {
    return getTreeNodeById(baseNode, nodeId);
  }).filter(Boolean);

  if (typeof baseNode.children !== 'string') {
    const customFieldNodes = baseNode.children.filter(isCustomFieldNode);

    return [...reservedNodes, ...customFieldNodes];
  }

  return reservedNodes;
};

/**
 * gets the first step in the form collection of a optin flow in the first node
 */
export const getFirstEditableFormWidget = (
  optinFlow: OptinFlow,
): FormWidget | undefined => {
  if (!optinFlow?.flow_nodes) return null;

  const firstNode = optinFlow.flow_nodes?.find(
    node => node.type === FlowNodeType.FORM_COLLECTION,
  );

  if (firstNode?.type === FlowNodeType.FORM_COLLECTION) {
    const firstStep =
      firstNode.forms?.find(form => form.step_type === FormWidgetType.STEP_1) ||
      firstNode.forms?.[0];
    if (firstStep) {
      return firstStep;
    }
  }
};

/**
 * returns true and discriminates the type to popup widget if both oneStep and twoStep keys dont exist (they exist on webpush widgets)
 */
export const isPopupPromptFormWidget = (
  formWidget: FormWidget,
): formWidget is FormWidget<TreeNode> => {
  if (formWidget?.config) {
    return !('oneStep' in formWidget.config && 'twoStep' in formWidget.config);
  }
  return false;
};

/**
 * returns true and discriminates the type to webpush widget if either oneStep or twoStep keys exist
 */
export const isWebpushPromptFormWidget = (
  formWidget: FormWidget,
): formWidget is FormWidget<OptinsData> => {
  if (formWidget?.config) {
    return 'oneStep' in formWidget.config || 'twoStep' in formWidget.config;
  }
  return false;
};

export const getOptinTypeFromWebpushPromptConfig = (activeWidget: string) => {
  return {
    oneStep: OptinType.BROWSER_WEBPUSH,
    twoStep: OptinType.CUSTOM_WEBPUSH,
  }[activeWidget || ''];
};

/**
 * gets the nearest form collection node, either in front or behind the current node(`searchForward`)
 * searches in front by default
 */
export const getNearestFormCollectionNode = ({
  optinNodes = [],
  currentNodeId,
  searchForward = true,
}: {
  optinNodes: OptinFlowNode[];
  currentNodeId: number;
  searchForward?: boolean;
}) => {
  const nodeIndex = optinNodes.findIndex(node => node.id === currentNodeId);
  if (nodeIndex === -1) return;

  const nextNode = optinNodes[nodeIndex + (searchForward ? 1 : -1)];
  if (!nextNode) return;

  if (nextNode?.type === FlowNodeType.FORM_COLLECTION) {
    return nextNode;
  }

  return getNearestFormCollectionNode({
    optinNodes,
    currentNodeId: nextNode.id,
    searchForward,
  });
};

/**
 * takes in the available optin nodes and current selected context and
 * checks if there is any nearby form collection node or a form widget that is editable (prev and next)
 */
export const checkIfNearestEditableNodeExists = ({
  optinNodes = [],
  currentNodeId,
  currentFormWidgetId,
}: {
  optinNodes: OptinFlowNode[];
  currentNodeId: number;
  currentFormWidgetId: number;
}): { prev: boolean; next: boolean } => {
  const selectedOptinNode = optinNodes.find(node => node.id === currentNodeId);
  if (!selectedOptinNode) return { prev: false, next: false };

  // only check if the selected node is a form collection node
  if (selectedOptinNode?.type === FlowNodeType.FORM_COLLECTION) {
    const selectedFormWidgetIdx = selectedOptinNode.forms.findIndex(
      form => form.id === currentFormWidgetId,
    );

    // check if there are nearby form widgets (step1, step2, teaser)
    let nextEditableExists = Boolean(
      selectedOptinNode.forms[selectedFormWidgetIdx + 1],
    );
    let prevEditableExists = Boolean(
      selectedOptinNode.forms[selectedFormWidgetIdx - 1],
    );

    // or else check if there are any nearby form collection nodes(webpush, popup)
    if (!prevEditableExists) {
      prevEditableExists = Boolean(
        getNearestFormCollectionNode({
          optinNodes,
          currentNodeId: selectedOptinNode?.id,
          searchForward: false,
        }),
      );
    }

    if (!nextEditableExists) {
      nextEditableExists = Boolean(
        getNearestFormCollectionNode({
          optinNodes,
          currentNodeId: selectedOptinNode?.id,
          searchForward: true,
        }),
      );
    }

    return {
      prev: prevEditableExists,
      next: nextEditableExists,
    };
  }
};

export const sortOptinFlow = optinFlowNode =>
  optinFlowNode.sort((a, b) => a.sort_key - b.sort_key);

export const groupOptinNodes = (
  nodes,
): Array<{
  waitTime: WaitTimeNode;
  form: FormCollectionNode;
  id: number;
}> => {
  const flowNodes = [];
  for (let i = 0; i < sortOptinFlow(nodes).length; i += 1) {
    if (i % 2 !== 0 && i > 0) {
      flowNodes.push({
        waitTime: nodes[i - 1],
        form: nodes[i],
        id: nodes[i].id,
      });
    }
  }

  return flowNodes;
};

export const isFreePricingSlab = (pricingSlab: PricingSlab) =>
  pricingSlab && Number(pricingSlab.price) === 0;

export const roundOffTwoDecimals = (value: number) =>
  (Math.round(value * 100) / 100).toFixed(2);

export const sumPrices = (prices: string[], returnWhole = false) => {
  const sum = prices.reduce((acc, price) => {
    const parsedPrice = parseFloat(price);

    if (!isNaN(parsedPrice)) {
      return acc + parsedPrice;
    }
  }, 0);

  if (returnWhole) return String(sum);

  return roundOffTwoDecimals(sum);
};

export const getPopupName = (node: FormCollectionNode, suffix?: string) => {
  const channel = node.channels?.[0];
  const channelName = ChannelDisplayName[channel] || channel;

  return `${channelName} ${suffix || ''}`;
};

export const getSubmittableFormWidget = (node: OptinFlowNode) => {
  if (node.type === FlowNodeType.FORM_COLLECTION) {
    return node.forms.find(form => form.step_type === FormWidgetType.STEP_1);
  }
};

export const isProduction = () =>
  process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

export const shouldDisableFirstWaitNode = (optin: OptinFlow) => {
  const { time_spent_enabled, scroll_depth_enabled, exit_intent } = optin;

  return time_spent_enabled || scroll_depth_enabled || exit_intent;
};

export const isLogoLocked = ({
  orderCount,
  user,
}: {
  orderCount: number;
  user: any;
}) => {
  // if (user?.website?.flags?.logo_lock !== 'disabled') {
  //   return (
  //     orderCount < 10_000 && user?.website?.current_plan?.plan_type === 'free'
  //   );
  // }

  return false;
};

export const getOverallDeviceType = (
  deviceType: DeviceType[],
): 'all' | 'mobile' | 'desktop' => {
  const isAllDevice =
    !deviceType || !deviceType?.length || deviceType.includes('all');
  const isMobileDevice = deviceType?.some(device => device.includes('mobile'));
  const isDesktopDevice = deviceType?.some(device =>
    device.includes('desktop'),
  );

  if (isAllDevice) return 'all';
  if (isMobileDevice) return 'mobile';
  if (isDesktopDevice) return 'desktop';

  return 'all';
};

export const getAIOptinsTemplateId = () =>
  isProduction() ? (26 as const) : (22 as const);

export const mapRange = (
  value: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
): number => {
  return ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
};

export const isEmbeddedForm = (optin: OptinFlow) => {
  return optin?.optin_type === OptinRenderType.EMBEDDED_FORMS;
};
